





















































































































































































































































































































import { defineComponent, ref, Ref, computed, reactive, watch } from '@vue/composition-api';
import { useNotification } from '@/composition/notification';
import { DataTableHeader } from 'vuetify';
import { useChatRoomUsers, ChatRoomUserProperty } from '@/composition/myTalk';
import { useConfirm } from '@/composition/confirm';
import { useWysiwygSetting } from '@/composition/wysiwygSetting';
import core from '@/admin/core';
import FcImage from '@/components/FcImage.vue';
import FcUserSelect from '@/components/FcUserSelect.vue';
import FcWysiwyg from '@/components/FcWysiwyg.vue';
import FcTableHeaderFilter from '@/components/FcTableHeaderFilter.vue';
import FcCheckFilter from '@/components/FcCheckFilter.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'MyTalkUsers',
  components: {
    FcImage,
    FcUserSelect,
    FcWysiwyg,
    FcTableHeaderFilter,
    FcCheckFilter,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    chatroomId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('mytalks'));

    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const chatRoomUsers = useChatRoomUsers(props.chatroomId);
    const wysiwygSetting = useWysiwygSetting();
    wysiwygSetting.fetchConsoleMenu();

    const pageTitle = computed(() => chatRoomUsers.chatRoomTitle || 'マイトーク');
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    const isPendingActionFilter: Ref<'all' | 'isPendingAction' | 'isNotPendingAction'> = ref('all');
    const displayIsPendingActionFilter = [
      { text: '全て', value: 'all' },
      { text: '未対応', value: 'isPendingAction' },
      { text: '対応済', value: 'isNotPendingAction' },
    ];

    const headers = [
      { text: 'ステータス', value: 'isPendingAction' },
      { text: 'ニックネーム', value: 'nickname' },
      { text: '最終投稿日（ユーザー）', value: 'latestUserChatMessage.postedDate' },
      { text: '本文', value: 'latestUserChatMessage.body', sortable: false },
      { text: '参加日時', value: 'joinDate' },
    ];

    // ヘッダーの表示項目設定
    const displayHeaders: Ref<DataTableHeader[]> = ref([]);
    // 初期で表示するヘッダーの項目
    const defaultHeaders = [
      { text: 'ステータス', value: 'isPendingAction' },
      { text: 'ニックネーム', value: 'nickname' },
      { text: '最終投稿日（ユーザー）', value: 'latestUserChatMessage.postedDate' },
      { text: '本文', value: 'latestUserChatMessage.body', sortable: false },
    ];

    // フリーワード検索
    const searchWord = ref('');
    const displayJoinUsers = computed(() => {
      return chatRoomUsers.joinUsers.filter(
        (user) => !searchWord.value || JSON.stringify(user).includes(searchWord.value)
      );
    });

    // ユーザー管理系
    const isUsernameTarget = computed(() => chatRoomUsers.chatRoomTargetType === 'usernames');
    const isUpdatingChatRoomTarget = ref(false);
    const warning = reactive({
      isActive: false,
      message: '',
    });
    const isActiveUserManagerDialog = ref(false);

    // ユーザー追加用の記載
    const isAddUser = ref(false);
    const selectedAddUsers: Ref<string[]> = ref([]);

    // 投稿管理
    const chatMessageForm: Ref<{
      target: 'all' | 'users' | null;
      users: string[];
      body: string;
      image: string;
    } | null> = ref(null);
    const isActiveCreateChatMessageDialog = ref(false);
    const isCreatingChatMessage = ref(false);

    // ユーザー削除用の記載
    const isDeleteUsers = ref(false);
    const selectedDeleteUsers: Ref<ChatRoomUserProperty[]> = ref([]);

    // 以下利用する関数
    // チャットメッセージ作成時の処理等
    function initChatMessageForm() {
      chatMessageForm.value = {
        target: null,
        users: [],
        body: '',
        image: '',
      };
      isActiveCreateChatMessageDialog.value = true;
    }
    async function createChatMessage() {
      if (!(await confirmDialog('本当に投稿を保存しますか？'))) return;
      if (!chatMessageForm.value) return;
      isCreatingChatMessage.value = true;
      try {
        const response = await chatRoomUsers.postMessage(chatMessageForm.value);
        if (response?.type === 'warning') notification.warning(response.message);
        else if (response?.type === 'success') notification.notify(response.message);
        isActiveCreateChatMessageDialog.value = false;
      } catch (error) {
        notification.error(error);
      } finally {
        isCreatingChatMessage.value = false;
      }
    }
    function closeCreateChatMessageDialog() {
      isActiveCreateChatMessageDialog.value = false;
    }

    // チャットルームの対象がusernamesの場合のユーザー追加削除処理等
    function openUserManagerDialog() {
      isActiveUserManagerDialog.value = true;
    }
    function closeUserManagerDialog() {
      isActiveUserManagerDialog.value = false;
    }

    function openUserSelector() {
      isAddUser.value = true;
      selectedAddUsers.value = [];
    }
    async function addUsers() {
      isUpdatingChatRoomTarget.value = true;
      warning.message = '';
      warning.isActive = false;
      try {
        const [invalidUsernames, failedUsernames] = await chatRoomUsers.addUsers(selectedAddUsers.value);
        if (invalidUsernames.length || failedUsernames.length)
          warning.message = `<p>追加できなかったユーザー：${invalidUsernames.length + failedUsernames.length}名</p>`;
        if (invalidUsernames.length)
          warning.message += `<p>不明なユーザーが${invalidUsernames.length}名指定されました
          <br>${invalidUsernames.join(', ')}</p>`;
        if (failedUsernames.length)
          warning.message += `<p>このトークに参加しているユーザーが${failedUsernames.length}名指定されました
          <br>${failedUsernames.join(', ')}</p>`;

        // 1人も追加できなかった場合はエラーを投げる
        if (selectedAddUsers.value.length - invalidUsernames.length - failedUsernames.length < 1) throw new Error();

        notification.notify('追加しました');
        await chatRoomUsers.getChatRoomUsers();
      } catch (e) {
        notification.error(typeof e === 'string' ? e : 'ユーザーの追加に失敗しました');
        console.error(e);
      } finally {
        isUpdatingChatRoomTarget.value = false;
        closeUserManagerDialog();
        isAddUser.value = false;
        selectedAddUsers.value = [];
        warning.isActive = !!warning.message;
      }
    }

    async function deleteUsers() {
      const deleteUserIds = selectedDeleteUsers.value.map((user) => user.username);
      isUpdatingChatRoomTarget.value = true;
      warning.message = '';
      warning.isActive = false;
      try {
        const [invalidUsernames, failedUsernames] = await chatRoomUsers.deleteUsers(deleteUserIds);
        if (invalidUsernames.length || failedUsernames.length)
          warning.message = `<p>削除できなかったユーザー：${invalidUsernames.length + failedUsernames.length}名</p>`;
        if (invalidUsernames.length)
          warning.message = `<p>不明なユーザーが${invalidUsernames.length}名指定されました
          <br>${invalidUsernames.join(', ')}</p>`;
        if (failedUsernames.length)
          warning.message += `<p>このトークに参加していないユーザーが${failedUsernames.length}名指定されました
          <br>${failedUsernames.join(', ')}</p>`;

        // 1人も削除できなかった場合はエラーを投げる
        if (deleteUserIds.length - invalidUsernames.length - failedUsernames.length < 1) throw new Error();

        notification.notify('削除しました');
        await chatRoomUsers.getChatRoomUsers();
      } catch (e) {
        notification.error(typeof e === 'string' ? e : 'ユーザーの削除に失敗しました');
        console.error(e);
      } finally {
        isUpdatingChatRoomTarget.value = false;
        closeUserManagerDialog();
        isDeleteUsers.value = false;
        selectedDeleteUsers.value = [];
        warning.isActive = !!warning.message;
      }
    }

    // もっと読み込む
    async function getNextUsers() {
      try {
        const isPendingActionFlag =
          isPendingActionFilter.value === 'all' ? undefined : isPendingActionFilter.value === 'isPendingAction';
        await chatRoomUsers.getChatRoomUsers({ isNextUsers: true, isPendingAction: isPendingActionFlag });
      } catch (error) {
        notification.error(error);
      }
    }

    watch(
      () => isPendingActionFilter.value,
      () => {
        chatRoomUsers.isInitializing = true;
        try {
          const isPendingActionFlag =
            isPendingActionFilter.value === 'all' ? undefined : isPendingActionFilter.value === 'isPendingAction';
          chatRoomUsers.getChatRoomUsers({ isPendingAction: isPendingActionFlag });
        } catch (error) {
          notification.error(error);
        } finally {
          chatRoomUsers.isInitializing = false;
        }
      }
    );

    return {
      pageTitle,
      myRoleSettings,
      hostingUrl: core.hostingUrl,
      isPendingActionFilter,
      displayIsPendingActionFilter,
      headers,
      defaultHeaders,
      displayHeaders,
      searchWord,
      displayJoinUsers,
      chatRoomUsers,
      wysiwygSetting,
      tableHeight,
      chatMessageForm,
      isCreatingChatMessage,
      isActiveCreateChatMessageDialog,
      createChatMessage,
      initChatMessageForm,
      closeCreateChatMessageDialog,
      isUsernameTarget,
      isUpdatingChatRoomTarget,
      isActiveUserManagerDialog,
      openUserManagerDialog,
      closeUserManagerDialog,
      openUserSelector,
      warning,
      isAddUser,
      selectedAddUsers,
      addUsers,
      isDeleteUsers,
      selectedDeleteUsers,
      deleteUsers,
      getNextUsers,
    };
  },
});
